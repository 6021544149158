import React from 'react';

import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Col, Row, Alert, Upload, Progress, Modal } from 'antd';
import Button from '@uz/unitz-components-web/Button';
import InputQuillEditor from '@uz/unitz-components-web/InputQuillEditor';
import Link from '@uz/unitz-components-web/Link';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form, FormItem, DatePicker, Select, Input, Checkbox, TimePicker } from 'formik-antd';
import i18n from 'i18n-js';
import { START_DATE_YEAR_ML_FORMAT } from '@vl/mod-utils/datetime';
import { datePickerLocaleMapping } from '@vl/mod-utils/datePickerLocaleMapping';
import gstyles from '@vl/gstyles';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import { Image } from '@uz/unitz-components-web/Image';
import cx from 'classnames';
import AddTeacherToCourseModal from '@uz/unitz-tool-components/AddTeacherToCourseModal';
import AddAssistantToCourseModal from '@uz/unitz-tool-components/AddAssistantToCourseModal';
import AddStudentToCourseModal from '@uz/unitz-tool-components/AddStudentToCourseModal';
import FormCreateCourseLesson from '@uz/unitz-tool-components/FormCreateCourseLesson';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import CustomTable from '@uz/unitz-components-web/CustomTable';
import { ResponsiveProps } from '@uz/unitz-components-web/Responsive';
import CourseAttendees from '@uz/unitz-tool-components/CourseAttendees';
import FormUseCourseLesson from '@uz/unitz-tool-components/FormCreateCourseLesson';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';

const CoverPhotoInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const AttachmentInputContainer = styled(CustomInput)`
  .ant-upload-list-item {
    .ant-upload-list-item-progress {
      padding-right: 32px;
    }
  }
`;
const AttachmentInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const CustomEditor = styled.div`
  .text-editor {
    .ql-toolbar {
      border-radius: 8px 8px 0 0;
      border-color: ${gstyles.colors.border};
    }
    .quill {
      .ql-container {
        border-radius: 0 0 8px 8px;
        border-color: ${gstyles.colors.border};
        .ql-editor {
          &::before {
            color: ${gstyles.colors.placeHolder};
          }
        }
      }
    }
  }
`;

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
  layout: 'vertical',
};

const gutter = {
  xs: 16,
  sm: 16,
  md: 16,
  lg: 16,
};

const SESSION_START_TIME_FORMAT = 'HH:mm';

const View8 = () => {
  const [isVisible, $isVisible] = React.useState(false);
  const [isAssistantVisible, $isAssistantVisible] = React.useState(false);
  const [isStudentVisible, $isStudentVisible] = React.useState(false);

  const locale = i18n.locale;

  const openModal = () => {
    $isVisible(true);
  };

  const hideModal = () => {
    $isVisible(false);
  };

  const openModalAssistant = () => {
    $isAssistantVisible(true);
  };

  const hideModalAssistant = () => {
    $isAssistantVisible(false);
  };

  const openModalStudent = () => {
    $isStudentVisible(true);
  };

  const hideModalStudent = () => {
    $isStudentVisible(false);
  };

  const dateFormatter = (value) => {
    return `${moment(value).format(_.get(START_DATE_YEAR_ML_FORMAT, locale, 'de'))}`;
  };

  return (
    <DIV className="component">
      {ctx.debug(() => {
        ctx.set('form', ctx.apply('formStepsModel.getForm'));
      })}

      <DIV forceCtx>
        <FormProvider form={ctx.get('form')}>
          <Form {...layout} className="course-form">
            <Row>
              <Col className="formStatus" span={24}>
                {!!ctx.get('form.status.success') && (
                  <div className="py-2">
                    <Alert message={ctx.get('form.status.success.message')} type="success" />
                  </div>
                )}
              </Col>
            </Row>
            {ctx.apply('formStepsModel.isStep', 0) && (
              <div className="steps-content-1">
                <FormItem
                  name="name"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Form.name')}
                    </span>
                  }
                  required
                >
                  <CustomInput>
                    <Input name="name" size="medium" />
                  </CustomInput>
                </FormItem>
                <FormItem
                  name="photo_url"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover')}
                    </span>
                  }
                >
                  <CustomInput>
                    <div className="relative">
                      <CoverPhotoInput
                        {...ctx.apply('form.getUploaderProps', { fieldName: 'photo_url', multiple: false })}
                        itemRender={() => null}
                      >
                        {!ctx.get('form.values.photo_url.0') ? (
                          <div className="flex flex-col items-center justify-center py-10">
                            <div className="text-center ant-upload-text">
                              {gstyles.icons({ name: 'photo-library', size: 24, fill: gstyles.colors.sub })}
                            </div>
                            <div className="text-sm my-2 font-normal ant-upload-hint text-main">
                              {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover_placeholder')}
                            </div>
                          </div>
                        ) : (
                          <div
                            className="w-full"
                            //  className="flex items-center justify-center"
                          >
                            <Image
                              alt="photo"
                              src={ctx.get('form.values.photo_url.0.url')}
                              // className="overflow-hidden rounded-lg"
                              // width={364}
                              // height={205}
                              style={{ height: 205, objectFit: 'contain' }}
                              size="cover"
                            />
                          </div>
                        )}
                      </CoverPhotoInput>
                      {ctx.get('form.values.photo_url.0') && (
                        <div className="absolute top-0 right-0">
                          <Button
                            type="link"
                            name="ink"
                            onClick={() => ctx.apply('form.setFieldValue', 'photo_url', [])}
                          >
                            <span>{gstyles.icons({ name: 'delete', size: 16, fill: gstyles.colors.sub })}</span>
                          </Button>
                        </div>
                      )}
                      {ctx.get('form.values.photo_url.0.status') === 'uploading' && (
                        <div className="absolute inset-0 flex items-center justify-center p-4 bg-black opacity-50">
                          <Progress width={80} type="circle" percent={ctx.get('form.values.photo_url.0.percent')} />
                        </div>
                      )}
                    </div>
                    <div className="flex items-center mt-2">
                      <div>{gstyles.icons({ name: 'info', size: 16, fill: gstyles.colors.sub })}</div>
                      <div style={{ paddingLeft: 4 }} className="text-sub text-xs font-normal">
                        {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cover_hint')}
                      </div>
                    </div>
                  </CustomInput>
                </FormItem>
                <FormItem
                  name="description"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.description')}
                    </span>
                  }
                  required
                >
                  <CustomEditor>
                    <InputQuillEditor name="description" toolbarId="description22" />
                  </CustomEditor>
                </FormItem>
                <FormItem
                  name="materials"
                  label={
                    <span className="text-sm font-semibold text-sub">
                      {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.material')}
                    </span>
                  }
                >
                  <div className="flex items-center mb-2">
                    <div>{gstyles.icons({ name: 'info', size: 16, fill: gstyles.colors.sub })}</div>
                    <div style={{ paddingLeft: 4 }} className="text-sub text-xs font-normal">
                      {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.material_hint')}
                    </div>
                  </div>
                  <AttachmentInputContainer>
                    <AttachmentInput {...ctx.apply('form.getUploaderProps', { fieldName: 'materials' })}>
                      <div className="flex flex-row items-center justify-center py-10">
                        <Button name="ink">{ctx.apply('i18n.t', 'Course.Info.Action.select_files')}</Button>
                        <div className="flex flex-row items-center justify-center ml-4">
                          <span>{gstyles.icons({ name: 'cloud-upload', size: 24, fill: gstyles.colors.sub })}</span>
                          <div className="text-base font-normal text-main pl-2">
                            {ctx.apply('i18n.t', 'Course.Info.Action.drop_files_here')}
                          </div>
                        </div>
                      </div>
                    </AttachmentInput>
                  </AttachmentInputContainer>
                </FormItem>
                <div className="mb-6">
                  {_.map(ctx.get('form.values.link_urls'), (benefit, index) => {
                    return (
                      <Row align="middle" key={_.get(benefit, 'id', '')} gutter={gutter}>
                        <Col span={22}>
                          <CustomInput>
                            <FormItem name={`link_urls[${index}].content`}>
                              <InputText
                                name={`link_urls[${index}].content`}
                                size="medium"
                                placeholder={ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.url_placeholder')}
                              />
                            </FormItem>
                          </CustomInput>
                        </Col>

                        <Col span={2}>
                          <div
                            className="cursor-pointer inline-block"
                            onClick={() => ctx.apply('form.array.remove', 'link_urls', index)}
                          >
                            {gstyles.icons({
                              name: 'remove-circle',
                              fill: gstyles.colors.red500,
                              size: 24,
                            })}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  <Row className="my-1" gutter={gutter}>
                    <Col xs={24} md={6}>
                      <Button
                        type="outline"
                        size="small"
                        className="w-full"
                        onClick={() => ctx.apply('form.array.add', 'link_urls')}
                        icon={gstyles.icons({
                          name: 'add-circle',
                          size: 20,
                          fill: gstyles.colors.brandb500,
                          className: 'inline-block mx-2',
                        })}
                      >
                        {ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.add_link')}
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            )}

            {ctx.apply('formStepsModel.isStep', 1) && (
              <div className="step-content-2">
                <FormItem
                  name="teachers"
                  label={
                    <div className="text-sm text-sub mb-4 font-semibold">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Form.teacher')}{' '}
                      {!!ctx.get('form.values.teachers.length') && `(${ctx.get('form.values.teachers.length')})`}
                    </div>
                  }
                  required
                >
                  {/* {!!ctx.get('form.errors.teachers') && (
                    <div className="py-2">
                      <Alert message={ctx.get('form.errors.teachers')} type="error" />
                    </div>
                  )} */}
                  <ResponsiveProps
                    xs={{
                      renderer: () => (
                        <CourseAttendees list={ctx.get('form.values.teachers')} removable form_name="teachers" />
                      ),
                    }}
                    md={{
                      renderer: () => (
                        <CustomTable
                          columns={ctx.get('tableColumn.columns')}
                          dataSource={ctx.get('tableColumn.dataSource', [])}
                          rowKey={(rec) => _.get(rec, 'id')}
                          pagination={false}
                          // loading={ctx.get('paginationModel.isLoading')}
                          // onChange={ctx.get('paginationModel.onChange')}
                          size="middle"
                        />
                      ),
                    }}
                  >
                    {(resProps) => !!resProps && resProps.renderer()}
                  </ResponsiveProps>
                </FormItem>

                <Row className="my-6">
                  <Col xs={24} md={12}>
                    <Row align="middle" gutter={gutter}>
                      <Col xs={24} md={12}>
                        <Button
                          type="outline"
                          className="mx-auto"
                          size="small"
                          block
                          icon={gstyles.icons({
                            name: 'add-circle',
                            size: 20,
                            fill: gstyles.colors.brandb500,
                            className: 'inline-block mx-2',
                          })}
                          onClick={openModal}
                        >
                          {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_teacher')}
                        </Button>
                        <Modal
                          width={1248}
                          footer={null}
                          className="rounded-lg overflow-hidden"
                          maskStyle={{ background: gstyles.colors.black300 }}
                          visible={isVisible}
                          centered
                          bodyStyle={{ paddingRight: 10, paddingLeft: 10 }}
                          wrapClassName={'my-modal'}
                          onCancel={hideModal}
                        >
                          <DIV>
                            {ctx.debug(() => {
                              ctx.set('closeAddTeacherModal', hideModal);
                            })}
                            <AddTeacherToCourseModal />
                          </DIV>
                        </Modal>
                      </Col>
                      {/* <Col className="mx-auto" span={12}>
                        <Button
                          type="outline"
                          name="secondary"
                          className="mx-auto"
                          size="small"
                          block
                          icon={gstyles.icons({
                            name: 'add-circle',
                            size: 20,
                            fill: gstyles.colors.branda500,
                            className: 'inline-block mx-2',
                          })}
                          onClick={openModalAssistant}
                        >
                          {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_assistant')}
                        </Button>
                        <Modal
                          width={1248}
                          footer={null}
                          className="rounded-lg overflow-hidden"
                          maskStyle={{ background: gstyles.colors.black300 }}
                          visible={isAssistantVisible}
                          centered
                          bodyStyle={{ paddingRight: 10, paddingLeft: 10 }}
                          wrapClassName={'my-modal'}
                          onCancel={hideModalAssistant}
                        >
                          <AddAssistantToCourseModal />
                        </Modal>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>

                <Row gutter={[16, 16]} justify="space-between">
                  <Col xs={24} md={18}>
                    <Row gutter={[16, 0]}>
                      <Col xs={24} md={12}>
                        <FormItem
                          name="start_at"
                          label={
                            <span className="text-sm font-semibold text-sub">
                              {ctx.apply('i18n.t', 'Course.Info.start_at_input_label')}
                            </span>
                          }
                          required
                        >
                          <CustomInput>
                            <DatePicker
                              allowClear={false}
                              size="medium"
                              locale={datePickerLocaleMapping(locale)}
                              format={dateFormatter}
                              className="w-full"
                              suffixIcon={gstyles.icons({
                                name: 'calendar',
                                size: 20,
                                fill: gstyles.colors.sub,
                              })}
                              name="start_at"
                              placeholder={ctx.apply('i18n.t', 'ZoomToolCourse.Form.select_date')}
                              disabledDate={(current) => {
                                return current && current.startOf('day') < moment().startOf('day');
                              }}
                            />
                          </CustomInput>
                        </FormItem>
                      </Col>
                      <Col xs={24} md={12}>
                        <Row gutter={[8, 0]} align="bottom">
                          <Col>
                            <FormItem
                              name="session_duration"
                              label={
                                <span className="text-sm font-semibold text-sub">
                                  {ctx.apply('i18n.t', 'ZoomToolCourse.Form.duration')}
                                </span>
                              }
                              required
                              style={{ margin: 0 }}
                            >
                              <CustomInput>
                                <Select
                                  showSearch
                                  size="medium"
                                  name="session_duration"
                                  suffixIcon={gstyles.icons({
                                    name: 'arrow-down3',
                                    size: 24,
                                    fill: gstyles.colors.sub,
                                    className: '-mt-2 -ml-2',
                                  })}
                                  required
                                  placeholder={ctx.apply('i18n.t', 'Course.Info.session_duration_input_placeholder')}
                                >
                                  {_.map(ctx.get('form.selectOptions.session_duration'), (item) => (
                                    <Select.Option value={item.value} key={item.value}>
                                      {item.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </CustomInput>
                            </FormItem>
                          </Col>
                          <Col>
                            <div className="text-sub font-normal text-sm">
                              {ctx.apply('i18n.t', 'ZoomToolCourse.Form.session_unit')}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col xs={24} md={4}>
                    <FormItem
                      name="type"
                      label={
                        <span className="text-sm font-semibold text-sub">
                          {ctx.apply('i18n.t', 'ZoomToolCourse.Form.course_type')}
                        </span>
                      }
                    >
                      <CustomInput>
                        <Select
                          showSearch
                          size="medium"
                          name="type"
                          suffixIcon={gstyles.icons({
                            name: 'arrow-down3',
                            size: 24,
                            fill: gstyles.colors.sub,
                            className: '-mt-2 -ml-2',
                          })}
                          required
                          placeholder={ctx.apply('i18n.t', 'ZoomToolCourse.Form.course_type')}
                        >
                          {_.map(ctx.get('form.selectOptions.course_type'), (item) => (
                            <Select.Option value={item.value} key={item.value}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </CustomInput>
                    </FormItem>
                  </Col> */}
                  <DIV>
                    <div className="p-4 border border-border rounded-xl w-full">
                      {!!ctx.get('form.status.error') && (
                        <div className="py-3">
                          <Alert message={ctx.get('form.status.error.message')} type="error" />
                        </div>
                      )}
                      <Row className="flex item-items justify-between">
                        <Col>
                          <div className="text-main text-base mb-4 font-semibold">
                            {ctx.apply('i18n.t', 'ZoomToolCourse.Form.time')}
                          </div>
                        </Col>
                        <Col>
                          <CustomInput>
                            <FormItem name="add_lesson_time_manually">
                              <Checkbox name="add_lesson_time_manually">
                                <div className="mb-0 text-sm text-main font-normal">
                                  {ctx.apply('i18n.t', 'ZoomToolCourse.Form.add_lesson_time_manually')}
                                </div>
                              </Checkbox>
                            </FormItem>
                          </CustomInput>
                        </Col>
                      </Row>
                      {!ctx.get('form.values.add_lesson_time_manually') && (
                        <>
                          <Row justify="center" align="middle">
                            <Col xs={24} md={18}>
                              {_.map(ctx.get('form.values.sessions'), (session, index) => {
                                const isRepeat = ctx.get(`form.values.sessions.${index}.repeat_pattern.length`);
                                const dateFormatter = (value) => {
                                  const TIME_FORMAT = 'HH:mm';
                                  const DATE_FORMAT = 'YYYY/MM/DD';
                                  if (isRepeat) {
                                    return `${value.format(TIME_FORMAT)}`;
                                  }
                                  return `${value.format(`${DATE_FORMAT} ${TIME_FORMAT}`)}`;
                                };
                                const endDateFormatter = (value) => {
                                  const TIME_FORMAT = 'HH:mm';
                                  const session_duration = ctx.get('form.values.session_duration') || 60 * 60;
                                  const endValue = value.clone().add(session_duration, 'second');
                                  if (isRepeat) {
                                    return `${endValue.format(TIME_FORMAT)}`;
                                  }
                                  return `${endValue.format(TIME_FORMAT)}`;
                                };
                                return (
                                  <Row
                                    gutter={[8, 8]}
                                    align="middle"
                                    className="py-2 border-b border-divider"
                                    key={`${index}`}
                                  >
                                    <Col xs={24} md={1}>
                                      <div className="font-semibold text-main">#{index + 1}</div>
                                    </Col>
                                    <Col xs={24} md={21}>
                                      <Row className="mb-4" justify="space-between">
                                        <Col xs={24} md={18}>
                                          <div style={{ gap: 10 }} className="flex md:px-6 flex-wrap md:flex-nowrap">
                                            {_.map(ctx.get('form.selectOptions.repeat_pattern'), (item, idx) => {
                                              return (
                                                <div
                                                  key={`${idx}`}
                                                  className={cx(
                                                    'text-sub py-2 px-6 font-semibold rounded-lg border cursor-pointer text-sm',
                                                    {
                                                      'border-brandb500 bg-white': _.includes(
                                                        ctx.get(`form.values.sessions[${index}].repeat_pattern`, []),
                                                        item.value
                                                      ),
                                                    },
                                                    {
                                                      'border-background2 bg-background2': !_.includes(
                                                        ctx.get(`form.values.sessions[${index}].repeat_pattern`, []),
                                                        item.value
                                                      ),
                                                    }
                                                  )}
                                                  onClick={() =>
                                                    ctx.apply(
                                                      'form.handleSelectSessions',
                                                      `sessions[${index}].repeat_pattern`,
                                                      item.value,
                                                      index
                                                    )
                                                  }
                                                >
                                                  {item.label}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </Col>
                                      </Row>
                                      {!!isRepeat && (
                                        <Row className="mt-2" align="middle" gutter={[0, 6]} key={index}>
                                          <Col xs={24} md={5}>
                                            <div className="flex md:items-center md:justify-center h-full">
                                              <div className="text-sm font-semibold text-main text-sub">
                                                {ctx.apply('i18n.t', 'ZoomToolCourse.Form.select_time')}
                                              </div>
                                            </div>
                                          </Col>
                                          <Col xs={24} md={18}>
                                            <Row align="middle" gutter={[16, 16]}>
                                              <Col>
                                                <CustomInput>
                                                  <FormItem name={`sessions[${index}].start_at`}>
                                                    {isRepeat ? (
                                                      <TimePicker
                                                        allowClear={false}
                                                        size="middle"
                                                        showTime={{
                                                          hourStep: 1,
                                                          minuteStep: 15,
                                                          format: SESSION_START_TIME_FORMAT,
                                                        }}
                                                        locale={datePickerLocaleMapping(locale)}
                                                        placeholder={ctx.apply(
                                                          'i18n.t',
                                                          'ZoomToolCourse.Form.select_time'
                                                        )}
                                                        suffixIcon={gstyles.icons({
                                                          name: 'calendar',
                                                          size: 20,
                                                          fill: gstyles.colors.sub,
                                                        })}
                                                        format={dateFormatter}
                                                        name={`sessions[${index}].start_at`}
                                                        className="w-full"
                                                        showNow={false}
                                                      />
                                                    ) : (
                                                      <DatePicker
                                                        allowClear={false}
                                                        disabled
                                                        size="middle"
                                                        showTime={{
                                                          hourStep: 1,
                                                          minuteStep: 15,
                                                          format: SESSION_START_TIME_FORMAT,
                                                        }}
                                                        suffixIcon={gstyles.icons({
                                                          name: 'calendar',
                                                          size: 20,
                                                          fill: gstyles.colors.sub,
                                                        })}
                                                        format={dateFormatter}
                                                        name={`sessions[${index}].start_at`}
                                                        className="w-full"
                                                      />
                                                    )}
                                                  </FormItem>
                                                </CustomInput>
                                              </Col>
                                              {!!ctx.get(`form.values.sessions[${index}].start_at`) && (
                                                <>
                                                  <Col>-</Col>
                                                  <Col>
                                                    <CustomInput>
                                                      <FormItem name={`sessions[${index}].start_at`}>
                                                        {isRepeat ? (
                                                          <TimePicker
                                                            allowClear={false}
                                                            size="middle"
                                                            showTime={{
                                                              hourStep: 1,
                                                              minuteStep: 15,
                                                              format: SESSION_START_TIME_FORMAT,
                                                            }}
                                                            disabled
                                                            locale={datePickerLocaleMapping(locale)}
                                                            suffixIcon={gstyles.icons({
                                                              name: 'calendar',
                                                              size: 20,
                                                              fill: gstyles.colors.sub,
                                                            })}
                                                            format={endDateFormatter}
                                                            name={`sessions[${index}].start_at`}
                                                            className="w-full"
                                                          />
                                                        ) : (
                                                          <DatePicker
                                                            allowClear={false}
                                                            disabled
                                                            size="middle"
                                                            showTime={{
                                                              hourStep: 1,
                                                              minuteStep: 15,
                                                              format: SESSION_START_TIME_FORMAT,
                                                            }}
                                                            suffixIcon={gstyles.icons({
                                                              name: 'calendar',
                                                              size: 20,
                                                              fill: gstyles.colors.sub,
                                                            })}
                                                            format={endDateFormatter}
                                                            name={`sessions[${index}].start_at`}
                                                            className="w-full"
                                                          />
                                                        )}
                                                      </FormItem>
                                                    </CustomInput>
                                                  </Col>
                                                </>
                                              )}
                                            </Row>
                                          </Col>
                                        </Row>
                                      )}
                                    </Col>
                                    <Col span={2}>
                                      <div
                                        className="cursor-pointer inline-block"
                                        onClick={() => ctx.apply('form.array.remove', 'sessions', index)}
                                      >
                                        {gstyles.icons({
                                          name: 'remove-circle',
                                          fill: gstyles.colors.red500,
                                          size: 24,
                                        })}
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </Col>
                          </Row>
                          <Row className="mt-4" align="middle" gutter={gutter}>
                            <Col className="mx-auto" xs={24} md={6}>
                              <Button
                                type="outline"
                                className="mx-auto"
                                size="small"
                                block
                                onClick={() => ctx.apply('form.array.add', 'sessions')}
                                icon={gstyles.icons({
                                  name: 'add-circle',
                                  size: 20,
                                  fill: gstyles.colors.brandb500,
                                  className: 'inline-block mx-2',
                                })}
                              >
                                {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_more_schedule')}
                              </Button>
                            </Col>
                          </Row>
                        </>
                      )}
                      {renderPropsComposer(
                        {
                          matcher: (props) =>
                            !_.get(props, 'add_lesson_time_manually') && !!_.get(props, 'sessions.[0].start_at'),
                          render: () => (
                            <div className="py-6">
                              <FormUseCourseLesson />
                            </div>
                          ),
                        },
                        {
                          matcher: (props) => !!_.get(props, 'add_lesson_time_manually'),
                          render: () => (
                            <div className="py-6">
                              <FormUseCourseLesson />
                            </div>
                          ),
                        },
                        () => null
                      )(ctx.get('form.values'))}
                    </div>
                  </DIV>
                </Row>
              </div>
            )}
            {ctx.apply('formStepsModel.isStep', 2) && (
              <div className="step-content-3">
                <FormItem
                  name="students"
                  label={
                    <div className="text-sm text-sub font-semibold">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Form.student')}{' '}
                      {!!ctx.get('form.values.students.length') && `(${ctx.get('form.values.students.length')})`}
                    </div>
                  }
                  required
                >
                  {/* {!!ctx.get('form.errors.students') && (
                    <div className="py-2">
                      <Alert message={ctx.get('form.errors.students')} type="error" />
                    </div>
                  )} */}
                  <ResponsiveProps
                    xs={{
                      renderer: () => (
                        <CourseAttendees list={ctx.get('form.values.students')} removable form_name="students" />
                      ),
                    }}
                    md={{
                      renderer: () => (
                        <CustomTable
                          columns={ctx.get('tableStudentColumn.columns')}
                          dataSource={ctx.get('tableStudentColumn.dataSource', [])}
                          rowKey={(rec) => _.get(rec, 'id')}
                          pagination={false}
                          // loading={ctx.get('paginationModel.isLoading')}
                          // onChange={ctx.get('paginationModel.onChange')}
                          size="middle"
                        />
                      ),
                    }}
                  >
                    {(resProps) => !!resProps && resProps.renderer()}
                  </ResponsiveProps>
                </FormItem>

                <Row className="my-6">
                  <Col xs={24} md={6}>
                    <Button
                      type="outline"
                      className="mx-auto"
                      size="small"
                      block
                      icon={gstyles.icons({
                        name: 'add-circle',
                        size: 20,
                        fill: gstyles.colors.brandb500,
                        className: 'inline-block mx-2',
                      })}
                      onClick={openModalStudent}
                    >
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Action.add_student')}
                    </Button>
                    <Modal
                      width={1248}
                      footer={null}
                      className="rounded-lg overflow-hidden"
                      maskStyle={{ background: gstyles.colors.black300 }}
                      visible={isStudentVisible}
                      centered
                      bodyStyle={{ paddingRight: 10, paddingLeft: 10 }}
                      wrapClassName={'my-modal'}
                      onCancel={hideModalStudent}
                    >
                      <DIV>
                        {ctx.debug(() => {
                          ctx.set('closeAddStudentModal', hideModalStudent);
                        })}
                        <AddStudentToCourseModal />
                      </DIV>
                    </Modal>
                  </Col>
                </Row>
              </div>
            )}
            <Row justify="end" className="mt-4 steps-action">
              <Col xs={24} md={12}>
                <ResponsiveProps
                  xs={{ style: { flexDirection: 'column-reverse' } }}
                  md={{ style: { flexDirection: 'row' } }}
                >
                  {(resProps) => (
                    <Row gutter={[12, 12]} {...resProps}>
                      <Col xs={24} md={12}>
                        {ctx.get('formStepsModel.current') === 0 ? (
                          <Link to={ctx.apply('routeStore.toUrl', 'toolAccountCourses')}>
                            <Button name="ink" className="w-full" loading={ctx.get('form.isSubmitting')}>
                              <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourseTemplate.Form.cancel')}</span>
                            </Button>
                          </Link>
                        ) : (
                          <Button name="ink" className="w-full" onClick={ctx.get('formStepsModel.prev')}>
                            <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourse.Action.back')}</span>
                          </Button>
                        )}
                      </Col>
                      <Col xs={24} md={12}>
                        {ctx.apply('formStepsModel.isFirst') && (
                          <Button
                            type="primary"
                            className="w-full"
                            loading={ctx.get('form.isSubmitting')}
                            onClick={ctx.get('form.submitForm')}
                            disabled={!ctx.get('form.canSubmit')}
                          >
                            <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourse.Action.next')}</span>
                          </Button>
                        )}
                        {ctx.get('formStepsModel.current') === 1 && (
                          <Button
                            type="primary"
                            className="w-full"
                            loading={ctx.get('form.isSubmitting')}
                            onClick={ctx.get('form.submitForm')}
                            disabled={!ctx.get('form.canSubmit')}
                          >
                            <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourse.Action.enroll_student')}</span>
                          </Button>
                        )}
                        {!!ctx.apply('formStepsModel.isLast') && (
                          <Button
                            type="primary"
                            className="w-full"
                            loading={ctx.get('form.isSubmitting')}
                            onClick={ctx.get('form.submitForm')}
                            disabled={!ctx.get('form.canSubmit')}
                          >
                            <span id="button-text">{ctx.apply('i18n.t', 'ZoomToolCourse.Action.done')}</span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  )}
                </ResponsiveProps>
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </DIV>
    </DIV>
  );
};

export default displayName(View8);
