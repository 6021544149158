import React from 'react';
import { bindings, hook } from '@vl/redata';
import { useFormik } from 'formik';
import AssetModelFormatter from '@uz/unitz-models/AssetModel/formatter';
import useRoute from '@vl/hooks/useGbRouteDe';
import moment from 'moment';
import MemberFormatter from '@uz/unitz-models/B2BMemberModel/formatter';
import useLocalStorage from '@vl/hooks/useLocalStorageWeb';
import gstyles from '@vl/gstyles';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import { flattenGet } from '@vl/mod-utils/flattenGet';
import { getUpdateColumns } from '@vl/mod-utils/gqlhelpers';
import { Avatar } from '@uz/unitz-components-web/Avatar';
import _ from 'lodash';
import { v4 as getUUid } from 'uuid';
import { notification } from 'antd';
import i18n from 'i18n-js';

const formatSessionData = (sessions) => {
  return _.map(sessions, (session) => {
    let repeat_pattern = _.get(session, 'repeat_pattern') || '';
    repeat_pattern = _.compact(`${repeat_pattern}`.replace('* * * * ', '').split(','));
    repeat_pattern = repeat_pattern.length ? _.map(repeat_pattern, (item) => parseInt(item)) : [];
    return {
      id: session.id,
      type: session.type,
      start_at: session.start_at,
      repeat_pattern,
    };
  });
};

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            stepForm_0: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const user_id = ctx.apply('currentUserModel.getUserId');
              const form = useFormik({
                initialValues: {
                  user_id,
                  name: ctx.get('@item.name'),
                  photo_url: !ctx.get('@item.photo_url')
                    ? ''
                    : [
                        {
                          url: ctx.get('@item.photo_url'),
                          status: 'success',
                        },
                      ],
                  description: ctx.get('@item.description'),
                  link_urls: _.map(ctx.get('@item.link_urls'), (item, index) => {
                    return {
                      id: index,
                      content: item,
                    };
                  }),
                  materials: _.map(ctx.get('@item.materials'), AssetModelFormatter.urlToFile()),
                },
                onSubmit: async (values, actions) => {
                  try {
                    ctx.apply('formStepsModel.next');
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  name: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  description: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                }),
              });

              _.assign(form, {
                canSubmit: form.isValid,
                array: {
                  add: (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const valueToInsert = _.clone(_.last(fieldArray));
                    const cleanItem = (val) => {
                      let rtn = '';
                      if (_.isPlainObject(val)) {
                        rtn = _.clone(val);
                        for (const key in valueToInsert) {
                          rtn[key] = cleanItem(valueToInsert[key]);
                        }
                      } else if (_.isArray(val)) {
                        rtn = [];
                      }
                      return rtn;
                    };

                    let newItemVal = cleanItem(valueToInsert);
                    if (_.isEmpty(valueToInsert)) {
                      newItemVal = {
                        name: '',
                        id: getUUid(),
                        photo_url: [],
                        description: '',
                        link_urls: [
                          {
                            content: '',
                          },
                        ],
                        materials: [],
                      };
                    } else {
                      newItemVal.id = getUUid();
                    }
                    form.setFieldValue(field, [...fieldArray, newItemVal]);
                  },
                  addMultiple: async (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const quantity = await _.parseInt(_.get(form, 'values.multiple_quantity', 0));

                    const array = [...fieldArray];
                    const newArr = [];
                    _.range(quantity).map(() => {
                      return newArr.push({
                        name: '',
                        id: getUUid(),
                        photo_url: [],
                        description: '',
                        link_urls: [
                          {
                            content: '',
                          },
                        ],
                        materials: [],
                      });
                    });
                    const data = _.concat(array, newArr);

                    form.setFieldValue(field, data);
                  },

                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });

              return form;
            }),

            stepForm_1: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const form = useFormik({
                initialValues: {
                  multiple_quantity: 0,
                  session_duration: ctx.get('@item.session_duration'),
                  start_at: ctx.get('@item.start_at'),
                  type: ctx.get('@item.type'),
                  sessions: ctx.get('@item.sessions') ? formatSessionData(ctx.get('@item.sessions')) : [],
                  lessons: ctx.get('@item.course_rooms.length')
                    ? _.map(ctx.get('@item.course_rooms'), (item) => {
                        return {
                          id: _.get(item, 'id', getUUid()),
                          name: _.get(item, 'name', ''),
                          description: _.get(item, 'description', ''),
                          photo_url: !_.get(item, 'photo_url', '')
                            ? ''
                            : [
                                {
                                  url: _.get(item, 'photo_url', ''),
                                  status: 'success',
                                },
                              ],
                          start_at: _.get(item, 'start_at', ''),
                          link_urls: _.map(_.get(item, 'link_urls'), (item, index) => {
                            return {
                              id: index,
                              content: item,
                            };
                          }),
                          materials: _.map(_.get(item, 'materials'), AssetModelFormatter.urlToFile()),
                        };
                      })
                    : [],
                  teachers: _.map(ctx.get('@item.course_teacher'), (teacher) => {
                    return {
                      ..._.omit(teacher, ['member', 'teacher_type']),
                      id: _.get(teacher, 'member.id', ''),
                      member_profile: _.get(teacher, 'member.member_profile', {}),
                    };
                  }),
                },
                onSubmit: async (values, actions) => {
                  try {
                    ctx.apply('formStepsModel.next');
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  session_duration: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  start_at: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                  lessons: Yup.array().of(
                    Yup.object().shape({
                      start_at: Yup.string().required(ctx.apply('i18n.t', 'Course.Info.Validation.required')),
                    })
                  ),
                  teachers: Yup.array().min(1, `${ctx.apply('i18n.t', 'ZoomToolError.missingTeacher')}`),
                }),
              });

              const calculateLessonTime = (number_of_lesson, start_date) => {
                const end_date = moment(start_date)
                  .add(number_of_lesson * 7, 'days')
                  .format('YYYY-MM-DD');
                const sessions = _.get(form, 'values.sessions', []);

                const getDates = (startDate, endDate) => {
                  let current = moment(startDate);
                  const end = moment(endDate);
                  const results = [];

                  while (current.isSameOrBefore(end)) {
                    const date = moment(current.clone())
                      .startOf('day')
                      .format('YYYY-MM-DD');

                    _.map(sessions, (day) => {
                      const session_start_at = _.get(day, 'start_at', '');
                      if (!session_start_at) {
                        return;
                      }
                      const start_at = `${date}${moment(session_start_at).format('THH:mm:ss.SSSSZ')}`;
                      if (_.includes(_.get(day, 'repeat_pattern'), current.clone().days())) {
                        results.push({
                          start_at,
                        });
                      }
                    });

                    current.add(1, 'day');
                  }

                  return _.take(results, number_of_lesson);
                };

                return getDates(start_date, end_date);
              };

              let lessonTimes = [];

              React.useEffect(() => {
                const lessons = _.get(form, 'values.lessons', []);
                const number_of_lesson = _.get(form, 'values.lessons.length');
                const start_date = _.get(form, 'values.start_at', '');
                const lessonTimesResult = calculateLessonTime(number_of_lesson, start_date);
                if (moment(_.get(lessonTimesResult, '0.start_at')).isSameOrBefore()) {
                  lessonTimes = _.drop(calculateLessonTime(number_of_lesson + 1, start_date));
                } else {
                  lessonTimes = calculateLessonTime(number_of_lesson, start_date);
                }

                const newLessons = _.map(lessons, (lesson, index) => {
                  return {
                    ...lesson,
                    start_at: _.get(lessonTimes, `[${index}].start_at`),
                  };
                });

                const cloneLessons = [...lessons];
                if (_.isEqual(_.sortBy(cloneLessons), _.sortBy(lessons))) form.setFieldValue('lessons', newLessons);
                if (_.has(form.touched, 'sessions')) form.setFieldValue('lessons', newLessons);
              }, [
                _.get(form, 'values.start_at'),
                _.get(form, 'values.sessions'),
                _.get(form, 'values.lessons.length'),
              ]);

              console.log(form);

              _.assign(form, {
                canSubmit: form.isValid,

                handleChangeCheckbox: (value) => {
                  const { checked, name } = value.event.target;

                  const field = _.get(value, 'field', '');
                  const rec = _.get(value, 'value', '');

                  if (checked) {
                    form.setFieldValue(field, [..._.get(form.values, field), { ...rec }]);
                  } else {
                    form.setFieldValue(
                      field,
                      _.filter(_.get(form, `values.${field}`), (v) => {
                        return _.get(v, 'id') !== name;
                      })
                    );
                  }
                },
                array: {
                  add: (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const valueToInsert = _.clone(_.last(fieldArray));
                    const cleanItem = (val) => {
                      let rtn = '';
                      if (_.isPlainObject(val)) {
                        rtn = _.clone(val);
                        for (const key in valueToInsert) {
                          rtn[key] = cleanItem(valueToInsert[key]);
                        }
                      } else if (_.isArray(val)) {
                        rtn = [];
                      }
                      return rtn;
                    };

                    let newItemVal = cleanItem(valueToInsert);
                    if (_.isEmpty(valueToInsert)) {
                      newItemVal = {
                        id: getUUid(),
                      };
                    } else {
                      newItemVal.id = getUUid();
                    }
                    form.setFieldValue(field, [...fieldArray, newItemVal]);
                  },
                  addMultiple: async (field) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.castArray(fieldValue);
                    const quantity = await _.parseInt(_.get(form, 'values.multiple_quantity', 0));

                    const array = [...fieldArray];
                    const newArr = [];
                    _.range(quantity).map((item) => {
                      return newArr.push({
                        name: '',
                        id: getUUid(),
                        photo_url: [],
                        description: '',
                        start_at: moment().startOf('day'),
                        link_urls: [
                          {
                            content: '',
                          },
                        ],
                        teachers: [],
                        materials: [],
                      });
                    });
                    const data = _.concat(array, newArr);

                    form.setFieldValue(field, data);
                  },

                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
                selectOptions: {
                  session_duration: _.range(15, 240, 15).map((count) => ({
                    value: count * 60,
                    label: `${count} ${ctx.apply('i18n.t', 'ZoomToolCourse.Form.minutes')}`,
                  })),
                  course_type: [
                    {
                      value: 'online',
                      label: ctx.apply('i18n.t', 'ZoomToolCourse.Form.online'),
                    },
                    {
                      value: 'offline',
                      label: ctx.apply('i18n.t', 'ZoomToolCourse.Form.offline'),
                    },
                  ],
                  repeat_pattern: _.sortBy(
                    [
                      ..._.range(0, 7, 1).map((count) => ({
                        value: count,
                        label: `${moment(count, 'e')
                          .startOf('week')
                          .isoWeekday(count)
                          .locale(i18n.locale)
                          .format('ddd')}`,
                      })),
                    ],
                    function(o) {
                      return o.value || Infinity;
                    }
                  ),
                },

                handleSelectAllSessions: (name, value) => {
                  if (value) {
                    form.setFieldValue(name, _.range(0, 7, 1));
                  } else {
                    form.setFieldValue(name, []);
                  }
                },
                handleSelectSessions: (name, value, index) => {
                  const currentValue = _.get(form, `values.${name}`, []);
                  let newValue = [];
                  if (_.isArray(value)) {
                    newValue = _.xor(currentValue, value);
                  } else {
                    newValue = _.xor(currentValue, [value]);
                  }
                  form.setFieldValue(name, newValue);
                  if (_.isEqual(newValue.sort(), _.range(0, 7, 1).sort())) {
                    form.setFieldValue(`sessions[${index}].select`, true);
                  }
                  if (_.isEmpty(newValue)) {
                    form.setFieldValue(`sessions[${index}].select`, false);
                  }
                },

                getUploaderProps: AssetModelFormatter.getUploaderProps(ctx, form),
              });
              return form;
            }),

            stepForm_2: hook((ctx) => {
              const Yup = ctx.get('ValidateHandler.Yup');
              const account_id = ctx.apply('accountModel.getAccountId');
              const route = useRoute();
              const user_id = ctx.apply('currentUserModel.getUserId');
              const form = useFormik({
                initialValues: {
                  students: _.map(_.uniqBy(ctx.get('@item.course_room_attendees'), 'member.user_id'), (student) => {
                    return {
                      ..._.omit(student, ['member']),
                      id: _.get(student, 'member.id', ''),
                      user_id: _.get(student, 'member.user_id', ''),
                      member_profile: _.get(student, 'member.member_profile', {}),
                    };
                  }),
                },
                onSubmit: async (values, actions) => {
                  try {
                    const formSteps = ctx.get('formSteps');
                    const course_id = ctx.get('@item.id');
                    const client = await getClient();

                    const course_payload = _.omit(
                      {
                        id: course_id,
                        // account_id,
                        user_id,
                        name: _.get(formSteps[0], 'form.values.name', ''),
                        description: _.get(formSteps[0], 'form.values.description', ''),
                        photo_url: _.get(formSteps[0], 'form.values.photo_url.0.url'),
                        materials: _.castArray(_.get(formSteps[0], 'form.values.materials', [])),
                        link_urls: `{${_.map(
                          _.castArray(_.get(formSteps[0], 'form.values.link_urls', [])),
                          'content'
                        ).join(',')}}`,
                        start_at: _.get(formSteps[1], 'form.values.start_at', ''),
                        type: _.get(formSteps[1], 'form.values.type', ''),
                        session_duration: _.get(formSteps[1], 'form.values.session_duration', ''),
                        sessions: {
                          data: _.map(_.get(formSteps[1], 'form.values.sessions', []), (session) => {
                            const rtn = _.omit(
                              {
                                ...session,
                                ...(_.get(session, 'repeat_pattern.length')
                                  ? {
                                      type: 'repeat',
                                      repeat_pattern: `* * * * ${session.repeat_pattern.join(',')}`,
                                    }
                                  : {
                                      type: 'repeat',
                                      repeat_pattern: '',
                                    }),
                              },
                              ['select']
                            );
                            // remove empty id
                            if (!rtn.id) {
                              _.unset(rtn, 'id');
                            }
                            return rtn;
                          }),
                          on_conflict: {
                            constraint: 'course_session_pkey',
                            update_columns: _.keys(_.first(_.get(formSteps[1], 'form.values.sessions', []))),
                          },
                        },
                      },
                      ['account_id']
                    );

                    const teacher_payload = _.map(_.get(formSteps[1], 'form.values.teachers', ''), (teacher) => {
                      return _.omit(
                        {
                          user_id: _.get(teacher, 'user_id', ''),
                          account_id,
                          course_id,
                          role: _.get(teacher, 'role', ''),
                        },
                        ['course_id']
                      );
                    });

                    const room_payload = _.map(_.get(formSteps[1], 'form.values.lessons', []), (lesson) => {
                      const start_at = _.get(lesson, 'start_at', '');
                      const duration = _.get(formSteps[1], 'form.values.session_duration', '');
                      let course_room_teachers_payload = {};
                      const teachers = _.get(lesson, 'teachers', []);
                      if (teachers.length) {
                        let teacher_payload_one = {};
                        course_room_teachers_payload = {
                          course_room_teachers: {
                            data: _.map(teachers, (teacher_payload) => {
                              teacher_payload_one = _.pick(teacher_payload, ['id', 'user_id']);
                              return {
                                ...teacher_payload_one,
                                account_id,
                                course_id,
                                teacher_type: _.get(teacher_payload, 'role', 'Teacher'),
                              };
                            }),
                            on_conflict: {
                              constraint: 'course_room_teacher_user_id_course_room_id_account_id_key',
                              update_columns: getUpdateColumns(teacher_payload_one),
                            },
                          },
                        };
                      }
                      let course_room_students_payload = {};
                      const students = [..._.get(formSteps[2], 'form.values.students', '')];
                      if (students.length) {
                        let student_payload_one = {};
                        course_room_students_payload = {
                          course_room_attendees: {
                            data: _.map(students, (student_payload) => {
                              student_payload_one = _.pick(student_payload, ['user_id']);
                              return {
                                ...student_payload_one,
                                account_id,
                                course_id,
                              };
                            }),
                            on_conflict: {
                              constraint: 'course_room_attendee_user_id_course_room_id_account_id_key',
                              update_columns: getUpdateColumns(student_payload_one),
                            },
                          },
                        };
                      }

                      return _.omit(
                        {
                          account_id,
                          course_id,
                          start_at,
                          end_at: moment(start_at)
                            .clone()
                            .add(duration, 'second')
                            .format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
                          ..._.pick(lesson, ['id']),
                          name: _.get(lesson, 'name', ''),
                          description: _.get(lesson, 'description', ''),
                          photo_url: _.get(lesson, 'photo_url.0.url'),
                          materials: _.castArray(_.get(lesson, 'materials', [])),
                          link_urls: `{${_.map(_.castArray(_.get(lesson, 'link_urls', [])), 'content').join(',')}}`,
                          duration,
                          ...course_room_teachers_payload,
                          ...course_room_students_payload,
                        },
                        ['course_id']
                      );
                    });

                    const course_res = await client.request(
                      gql`
                        mutation EditCourse(
                          $object: b2b_course_insert_input!
                          $on_conflict: b2b_course_on_conflict!
                          $delete_course_room_exp: b2b_course_room_bool_exp!
                          $delete_course_teacher_exp: b2b_course_teacher_bool_exp!
                          $delete_course_room_teacher_exp: b2b_course_room_teacher_bool_exp!
                          $delete_course_room_attendee_exp: b2b_course_room_attendee_bool_exp!
                        ) {
                          delete_b2b_course_room(where: $delete_course_room_exp) {
                            affected_rows
                          }
                          delete_b2b_course_teacher(where: $delete_course_teacher_exp) {
                            affected_rows
                          }
                          delete_b2b_course_room_teacher(where: $delete_course_room_teacher_exp) {
                            affected_rows
                          }
                          delete_b2b_course_room_attendee(where: $delete_course_room_attendee_exp) {
                            affected_rows
                          }
                          course: insert_b2b_course_one(object: $object, on_conflict: $on_conflict) {
                            id
                            course_rooms {
                              id
                              course_room_teachers {
                                id
                              }
                            }
                            course_teacher {
                              id
                              user_id
                            }
                          }
                        }
                      `,
                      {
                        delete_course_room_attendee_exp: {
                          _or: _.map(room_payload, (room) => {
                            return {
                              course_id: { _eq: course_id },
                              course_room_id: { _eq: room.id },
                              user_id: { _nin: flattenGet(room, 'course_room_attendees.data.user_id') },
                            };
                          }),
                        },
                        delete_course_room_teacher_exp: {
                          _or: _.map(room_payload, (room) => {
                            return {
                              course_id: { _eq: course_id },
                              course_room_id: { _eq: room.id },
                              user_id: { _nin: flattenGet(room, 'course_room_teachers.data.user_id') },
                            };
                          }),
                        },
                        delete_course_room_exp: {
                          course_id: { _eq: course_id },
                          id: { _nin: flattenGet(room_payload, 'id') },
                        },
                        delete_course_teacher_exp: {
                          course_id: { _eq: course_id },
                          user_id: { _nin: flattenGet(teacher_payload, 'user_id') },
                        },
                        object: {
                          ...course_payload,
                          course_rooms: {
                            data: room_payload,
                            on_conflict: {
                              constraint: 'course_room_pkey',
                              update_columns: getUpdateColumns(room_payload),
                            },
                          },
                          course_teacher: {
                            data: teacher_payload,
                            on_conflict: {
                              constraint: 'course_teacher_user_id_course_id_key',
                              update_columns: getUpdateColumns(teacher_payload),
                            },
                          },
                        },
                        on_conflict: {
                          constraint: 'course_pkey',
                          update_columns: getUpdateColumns(course_payload),
                        },
                      }
                    );

                    if (course_res) {
                      route.navigate(ctx.apply('routeStore.toUrl', 'toolAccountCourseDetail', { id: course_id }));
                    } else {
                      notification.error({
                        placement: 'bottomRight',
                        message: ctx.apply('i18n.t', 'ZoomToolError.CourseTemplate.edit.fail'),
                      });
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },
                validationSchema: Yup.object().shape({
                  students: Yup.array().min(1, `${ctx.apply('i18n.t', 'ZoomToolError.missingStudent')}`),
                }),
              });

              _.assign(form, {
                canSubmit: form.isValid,
                handleChangeCheckbox: (value) => {
                  const { checked, name } = value.event.target;

                  const field = _.get(value, 'field', '');
                  const rec = _.get(value, 'value', '');

                  if (checked) {
                    form.setFieldValue(field, [..._.get(form.values, field), { ...rec }]);
                  } else {
                    form.setFieldValue(
                      field,
                      _.filter(_.get(form, `values.${field}`), (v) => {
                        return _.get(v, 'id') !== name;
                      })
                    );
                  }
                },
                array: {
                  remove: (field, index) => {
                    const fieldValue = _.get(form, `values.${field}`);
                    const fieldArray = _.cloneDeep(_.castArray(fieldValue));
                    fieldArray.splice(index, 1);
                    const newValue = [...fieldArray];
                    form.setFieldValue(field, newValue);
                  },
                },
              });
              return form;
            }),

            allColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return <div>{index + 1}</div>;
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.teacher')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-5">
                          <div className="flex flex-col">
                            <div className="text-xs">{MemberFormatter.fullName(ctx)(val)}</div>
                            {/* <div className="text-xs"> Type: {_.get(val, 'role', '')}</div> */}
                          </div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.email')}
                    </div>
                  ),
                  render: (val) => {
                    return <div className="text-xs">{MemberFormatter.email(ctx)(val)}</div>;
                  },
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.actions')}
                    </div>
                  ),
                  width: 300,
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        <div
                          className="inline-block cursor-pointer"
                          onClick={() => ctx.apply('stepForm_1.array.remove', 'teachers', index)}
                        >
                          {gstyles.icons({
                            name: 'remove-circle',
                            fill: gstyles.colors.red500,
                            size: 24,
                          })}
                        </div>
                      </div>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableName: hook(() => 'tblCourseTeacher'),

            tableColumn: hook((ctx) => {
              const data = ctx.get('stepForm_1.values.teachers');
              const tableName = ctx.get('tableName', 'tblCourseTeacher');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allColumns');

              const columns = _.filter(ctx.get('allColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                dataSource: data,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),

            allStudentColumns: hook((ctx) => {
              const columns = [
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolMember.Table.number_order')}
                    </div>
                  ),
                  render: (text, record, index) => {
                    return <div>{index + 1}</div>;
                  },
                  width: 50,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.student')}
                    </div>
                  ),
                  render: (val) => {
                    return (
                      <div className="identify-container">
                        <div className="table-cell align-top">
                          <Avatar size={40} src={MemberFormatter.avatarUrl(ctx)(val)} />
                        </div>

                        <div style={{ verticalAlign: 'middle' }} className="table-cell align-middle pl-5">
                          <div className="flex flex-col">
                            <div className="text-xs">{MemberFormatter.fullName(ctx)(val)}</div>
                            {/* <div className="text-xs"> Type: {_.get(val, 'role', '')}</div> */}
                          </div>
                        </div>
                      </div>
                    );
                  },
                  require: true,
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.email')}
                    </div>
                  ),
                  render: (val) => {
                    return <div className="text-xs">{MemberFormatter.email(ctx)(val)}</div>;
                  },
                },
                {
                  title: (
                    <div className="text-sub font-semibold text-xs text-center">
                      {ctx.apply('i18n.t', 'ZoomToolCourse.Table.actions')}
                    </div>
                  ),
                  width: 300,
                  render: (text, record, index) => {
                    return (
                      <div className="text-center">
                        <div
                          className="inline-block cursor-pointer"
                          onClick={() => ctx.apply('stepForm_2.array.remove', 'students', index)}
                        >
                          {gstyles.icons({
                            name: 'remove-circle',
                            fill: gstyles.colors.red500,
                            size: 24,
                          })}
                        </div>
                      </div>
                    );
                  },
                },
              ];
              return columns;
            }),

            tableStudentName: hook(() => 'tblCourseStudent'),

            tableStudentColumn: hook((ctx) => {
              const data = ctx.get('stepForm_2.values.students');
              const tableName = ctx.get('tableName', 'tblCourseStudent');
              const [tableSettings, $tableSettings] = useLocalStorage(`@UZ::TableSettings::${tableName}`, {
                columns: [],
              });
              const ref = React.useRef({});

              const allColumns = ctx.get('allStudentColumns');

              const columns = _.filter(ctx.get('allStudentColumns'), (col) => {
                const key = _.get(col, 'key');
                if (_.get(tableSettings, 'columns.length')) {
                  return _.get(col, 'required') || _.includes(tableSettings.columns, key);
                }
                return _.get(col, 'defaultSelected', true);
              });
              const selectedColumns = _.map(columns, 'key');
              _.assign(ref.current, { tableSettings, $tableSettings });

              return {
                dataSource: data,
                columns,
                allColumns,
                selectedColumns,
                selectColumns: (values) => {
                  ref.current.$tableSettings({ ...ref.current.tableSettings, columns: values });
                },
              };
            }),
            formSteps: hook((ctx) => {
              return [
                {
                  form: ctx.get('stepForm_0'),
                },
                {
                  form: ctx.get('stepForm_1'),
                },
                {
                  form: ctx.get('stepForm_2'),
                },
              ];
            }),

            formStepsModel: hook((ctx) => {
              const [currentStep, $currentStep] = React.useState(0);
              const formSteps = ctx.get('formSteps');
              const stepCount = formSteps.length;
              const ref = React.useRef({});
              _.assign(ref.current, { currentStep, $currentStep });
              const model = {
                current: ref.current.currentStep,
                isStep: (index) => index === ref.current.currentStep,
                next: () => {
                  ref.current.$currentStep(Math.min(ref.current.currentStep + 1, stepCount - 1));
                },
                prev: () => {
                  ref.current.$currentStep(Math.max(ref.current.currentStep - 1, 0));
                },
                isLast: () => {
                  return ref.current.currentStep === stepCount - 1;
                },
                isFirst: () => {
                  return ref.current.currentStep === 0;
                },
                hasNext: () => {
                  return stepCount > 1 && !model.isLast();
                },
                hasPrev: () => {
                  return stepCount > 1 && !model.isFirst();
                },
                getForm: () => {
                  return ctx.get(`formSteps.${ref.current.currentStep}.form`);
                },
              };
              return model;
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
