import React from 'react';
import { bindings, hook } from '@vl/redata';
import useLoadingModel from '@vl/hooks/useLoadingModel';
import usePromiseSource from '@vl/hooks/usePromiseSource';
import useRoute from '@vl/hooks/useGbRouteDe';
import { getClient, gql } from '@vl/mod-clients/hasuraApp';
import _ from 'lodash';

const bindData = bindings({
  pageContainer: {
    rules: [
      [
        'data',
        {
          data: {
            loadingModel: hook(useLoadingModel(() => ({ fetchTemplate: false }))),
            getCourseId: hook(() => {
              const route = useRoute();
              const paramsData = _.get(route, 'params.id', '');
              return paramsData;
            }),

            '@item': hook((ctx) => {
              const id = ctx.get('getCourseId');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!id) return null;
                    const client = await getClient();
                    const data = await client.request(
                      gql`
                        query GetCourseInfo($id: uuid!) {
                          b2b_course_by_pk(id: $id) {
                            id
                            name
                            description
                            photo_url
                            type
                            session_duration
                            account_id
                            materials
                            start_at
                            link_urls
                            status
                            course_teacher {
                              role
                              user_id
                              member {
                                id
                                member_profile {
                                  first_name
                                  last_name
                                  email
                                  avatar_url
                                }
                              }
                            }
                            course_room_attendees {
                              member {
                                id
                                user_id
                                member_profile {
                                  first_name
                                  last_name
                                  email
                                  avatar_url
                                }
                              }
                            }
                            sessions {
                              id
                              type
                              start_at
                              repeat_pattern
                            }
                            course_rooms(order_by: { start_at: asc_nulls_last }) {
                              id
                              name
                              photo_url
                              description
                              start_at
                              end_at
                              status
                              link_urls
                              materials
                              course_room_teachers {
                                course_id
                                course_room_id
                                user_id
                                teacher_type
                                member {
                                  id
                                  member_profile {
                                    first_name
                                    last_name
                                    email
                                    avatar_url
                                  }
                                }
                              }
                            }
                          }
                        }
                      `,
                      { id }
                    );
                    return data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [id]
              );
              return _.get(data, 'b2b_course_by_pk', {});
            }),

            getAccountZone: hook((ctx) => {
              const route = useRoute();
              const routeParams = route.getParams();
              const account_profile_id = _.get(routeParams, 'account_profile.id', '');
              const data = usePromiseSource(
                async () => {
                  try {
                    if (!account_profile_id) return null;
                    const client = await getClient();
                    const data = await client.request(
                      gql`
                        query GetAccountZone($id: uuid!) {
                          b2b_account_profile_by_pk(id: $id) {
                            holiday_zone
                          }
                        }
                      `,
                      { id: account_profile_id }
                    );
                    ctx.apply('loadingModel.clearLoading', 'fetchData');
                    return data;
                  } catch (err) {
                    console.log('error', err);
                  }
                },
                null,
                [account_profile_id]
              );

              return _.get(data, 'b2b_account_profile_by_pk', '');
            }),
          },
        },
      ],
    ],
  },
  isLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => {
            return _.isEmpty(ctx.get('@item'));
          }),
        },
      ],
    ],
  },
  notLoading: {
    rules: [
      [
        'display',
        {
          display: hook((ctx) => !_.isEmpty(ctx.get('@item'))),
        },
      ],
    ],
  },
});

export default bindData;
